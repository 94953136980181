<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-spacer />
      <v-btn @click="showModal" color="error" :loading="loading">Clear Cache</v-btn>
      <v-spacer />
    </v-row>
    <v-row align="center" justify="center" v-if="confirmed">
      <v-col cols="12" class="text-center">
        <h4>Done!</h4>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="400">
      <v-card>
        <v-card-text class="pt-4">
          <h3>Are you sure you want to destroy the world?</h3>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined text @click="handleConfirm">
            No
          </v-btn>
          <v-btn color="primary" text @click="handleConfirm"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: "AdminView",
  data() {
    return {
      dialog: false,
      confirmed: false,
      loading: false
    };
  },
  methods: {
    showModal() {
      this.confirmed = false;
      this.dialog = true;
    },
    async handleConfirm() {
      this.dialog = false;
      this.loading = true;
      await this.$admin.http.post("/api/services-update");
      this.confirmed = true;
      this.loading = false;
    }
  }
};
</script>
